import React from "react";
import Back from "structure/Menu/Back";
import MovementReportV2 from "structure/MovementReportV2";
import PlainOption from "structure/Menu/PlainOption";
import RedirectButton from "structure/Menu/Redirect";
import TextIcon from "structure/Menu/TextIcon";
import BankAccountReportV2 from "structure/BankAccountReportV2";
import ModalMassiveInvoiceDownload from "structure/ModalMassiveInvoiceDownload";

export default function Reports() {
  return (
    <>
      <Back menu="banks" text="Inicio > Bancos > Reportes" />

      <BankAccountReportV2>
        <PlainOption icon="banks" text="Estado de cuenta" />
      </BankAccountReportV2>

      <MovementReportV2 id={null}>
        <PlainOption icon="banks" text="Movimientos" />
      </MovementReportV2>

      <RedirectButton
        to="/v2/bancos/saldos"
        text={<TextIcon text="Saldo cuentas bancarias" icon="banks" />}
      />

      <RedirectButton
        to="/v2/bancos/flujo-efectivo"
        text={<TextIcon text="Flujo de efectivo" icon="banks" />}
      />

      <RedirectButton
        to="/v2/bancos/proyeccion-flujo-efectivo"
        text={<TextIcon text="Proyección de flujo de efectivo" icon="banks" />}
      />

      <ModalMassiveInvoiceDownload typeInvoice="complementosE">
        <PlainOption icon="download" text="Descargar complementos" />
      </ModalMassiveInvoiceDownload>
    </>
  );
}
