import React, { useContext } from "react";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";
import { ContextMassiveInvoiceDownload } from "..";

export default function StepsHeader() {
  const hook = useContext(ContextMassiveInvoiceDownload);

  return (
    <div className="mb-4">
      <Steps current={hook.state.step} onChange={hook.setStep}>
        <Steps.Step title="Configurar permisos" />
        <Steps.Step
          title="Descargar"
          disabled={hook.state.step <= 1}
        />
      </Steps>
    </div>
  );
}
