import React, { useContext, useRef } from "react";
import { ContextMassiveInvoiceDownload } from "..";

export default function Header() {
  // "facturas" | "complementos" | "notasCredito";

  const hook = useContext(ContextMassiveInvoiceDownload);

  const LABELS = useRef({
    facturasE: "Descargar facturas emitidas",
    complementosE: "Descargar complementos emitidos",
    notasCreditoE: "Descargar notas de crédito emitdas",
    facturasR: "Descargar facturas recibidas",
    notasCreditoR: "Descargar notas de crédito recibidas",
  });

  return <p>{LABELS.current[hook.type] || "Descargar facturas"}</p>;
}
