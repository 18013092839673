import React, { useContext } from "react";
import GuideMassiveDownload from "../GuideMassiveDownload";
import { ContextMassiveInvoiceDownload } from "..";

export default function Step1() {
  const hook = useContext(ContextMassiveInvoiceDownload);

  if (hook.state.step !== 0) return <></>;

  return <GuideMassiveDownload />;
}
