import React, { useContext } from "react";
import { MenuContext } from "structure/Menu";
import TextIcon from "structure/Menu/TextIcon";
import scss from "../../index.module.scss";
import RedirectButton from "structure/Menu/Redirect";
import CatalogueDirectory from "structure/CatalogueDirectory";
import MicrosoftCsp from "structure/MicrosoftCsp";
import ModalMassiveInvoiceDownload from "structure/ModalMassiveInvoiceDownload";
import PlainOption from "structure/Menu/PlainOption";

export default function Reports() {
  const { setMenuToDisplay } = useContext(MenuContext);

  return (
    <>
      <p onClick={() => setMenuToDisplay("ventas")}>
        <b>
          <TextIcon icon="back" text="Ventas > Reportes" />
        </b>
      </p>

      <RedirectButton
        text={<TextIcon icon="document" text="Cuentas por cobrar" />}
        to={"/ventas/cxc"}
      />

      <CatalogueDirectory type="clients">
        <p className={scss.item}>
          <TextIcon icon="administration" text="Catálogo clientes" />
        </p>
      </CatalogueDirectory>

      <RedirectButton
        text={
          <TextIcon icon="administrationMoney" text="Histórico de contratos" />
        }
        to={"/ventas/historico-contratos"}
      />

      <RedirectButton
        text={
          <TextIcon icon="administrationMoney" text="Saldos y movimientos" />
        }
        to={"/saldos-y-movimientos"}
      />

      <RedirectButton
        text={
          <TextIcon icon="administrationMoney" text="Antigüedad de saldos" />
        }
        to={"/v2/bancos/cxc/antiguedad-saldos"}
      />

      <RedirectButton
        text={<TextIcon icon="administrationMoney" text="Pareto" />}
        to={"/compras/pareto"}
      />

      <MicrosoftCsp type="ventas">
        <p className={scss.item}>
          <TextIcon icon="administration" text="BI" />
        </p>
      </MicrosoftCsp>

      <ModalMassiveInvoiceDownload>
        <PlainOption icon="download" text="Descargar facturas emitidas" />
      </ModalMassiveInvoiceDownload>

      <ModalMassiveInvoiceDownload typeInvoice="notasCreditoE">
        <PlainOption icon="download" text="Descargar notas de crédito emitidas" />
      </ModalMassiveInvoiceDownload>
    </>
  );
}
