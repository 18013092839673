import usePermissions from "customHooks/usePermissions";
import React from "react";
import RedirectButton from "../Redirect";
import TextIcon from "../TextIcon";
import { ChangeMenuView } from "../ChangeMenuView";
import Back from "../Back";
import PlainOption from "../PlainOption";
import ModalMassiveInvoiceDownload from "structure/ModalMassiveInvoiceDownload";

export default function Sells() {
  const { checkPermission } = usePermissions();

  if (!checkPermission("8d6a195f-4024-46c8-84fd-176c2946001b")) return <></>;

  return (
    <>
      <Back menu="general" text="Inicio > Ventas" />

      <RedirectButton
        permission="82457369-4d57-46fb-a468-49159aefb8bc"
        text={<TextIcon icon="contract" text="Contratos" />}
        to={"/ventas/contratos"}
      />

      <RedirectButton
        permission="9d1d254e-315f-4084-a7d9-04f37cfc6900"
        text={<TextIcon icon="administrationMoney" text="Cuotas" />}
        to={"/ventas/cuotas"}
      />

      <RedirectButton
        text={<TextIcon icon="administrationMoney" text="Cobranza" />}
        to={"/ventas/cobranza"}
      />

      <RedirectButton
        permission="7d769fc2-aaee-4a22-a4e6-3b509a6ce4b6"
        text={<TextIcon icon="document" text="Facturas emitidas" />}
        to={"/administracion/facturas-emitidas"}
      />
      <RedirectButton
        permission="d91b10ef-8586-4073-857a-9829f8f0512d"
        to="/ventas/notas-de-credito"
        text={
          <TextIcon icon="noteCreditEmitted" text="Notas de crédito emitidas" />
        }
      />
      <RedirectButton
        permission="dcf3e4a1-40df-4c3f-84bd-9de21c2b5699"
        text={<TextIcon icon="document" text="Pedidos" />}
        to={"/administracion/pedidos?from=pedidos"}
      />

      {/* <ModalMassiveInvoiceDownload>
        <PlainOption icon="download" text="Descargar facturas emitidas" />
      </ModalMassiveInvoiceDownload>

      <ModalMassiveInvoiceDownload typeInvoice="notasCreditoE">
        <PlainOption icon="download" text="Descargar notas de crédito emitidas" />
      </ModalMassiveInvoiceDownload> */}

      <ChangeMenuView icon="reports" text="Reportes" menu="ventasReportes" />
    </>
  );
}
