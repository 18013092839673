import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { ContextMassiveInvoiceDownload } from "..";

export default function Back() {
  const hook = useContext(ContextMassiveInvoiceDownload);

  return (
    <ButtonV2
      onClick={() => hook.setStep(hook.state.step - 1)}
      disabled={hook.state.step === 0}
    >
      <span>Regresar</span>
    </ButtonV2>
  );
}
