import React, { useContext } from "react";
import { Tutorial } from "structure/Tutorial";
import { CustomTutorial } from "components/general/CustomTutorial";
import { ContextMassiveInvoiceDownload } from "..";

export default function GuideMassiveDownload() {
  const hook = useContext(ContextMassiveInvoiceDownload);

  if (hook.state.state === "downloading") return <></>;

  return <div className="d-flex flex-column align-items-center justify-content-center" style={{gap:"10px"}}>
  
  <p>Favor de habilitar dando click en el icono que se muestra a la izquierda de la dirección Web de su página y posteriormente asegurar que la opción de descargas está habilitada.</p>

  <img className="w-100" src="/images/tutorial.png" alt="tutorial_massive_download" />
  
  </div>

  return (
    <>
      <h2 className="text-warning font-weight-bold text-center" style={{
        fontSize:"1.75rem"
      }}>
        👉❕ Permisos de descarga automática requeridos ❕👈
      </h2>
      <p>
        Para completar esta acción, es necesario habilitar los permisos de
        descarga automática en su navegador. Esto permitirá que los archivos se
        descarguen sin necesidad de confirmación adicional. 🔍 
      </p>
      <br />
      <p>Por favor, siga
        la guía visual adjunta (dando click en el icono de algún navegador) para activar esta opción en su navegador. Si
        tiene alguna duda, no dude en contactarnos para asistencia adicional. 👇</p>

      <Tutorial
        chrome={
          <CustomTutorial
            folder="images/massiveDownload/"
            labels={["Dar permisos de descarga", "Aplicar cambios"]}
          />
        }
        edge={
          <CustomTutorial
          folder="images/massiveDownloadEdge/"
          labels={['Dar permisos de descarga', 'Aplicar cambios','Opcional - Popup de permisos']} 
          />
        }
      />
    </>
  );
}
