// import { Modal } from "components/general/Modal";
import useDialog from "customHooks/useDialog";
import React, { useEffect } from "react";
import {
  Back,
  Header,
  Next,
  Step1,
  Step2,
  StepsHeader,
  WrapperMassiveInvoiceDownload,
} from "structure/MassiveInvoiceDownload";
import Dialog from "structure/Dialog";

/**
 * Render a modal to get the invoices of the system
 * @param {import("./types").PropsModalMassiveInvoiceDownload} props - Props
 */
export default function ModalMassiveInvoiceDownload({ children , typeInvoice = "facturasE" }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const dialog = useDialog();

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  useEffect(() => {
    if (isOpen) {
      dialog.open();
      return;
    }

    dialog.close();
  }, [isOpen]);

  return (
    <>
      <div onClick={open}>{children}</div>

      <WrapperMassiveInvoiceDownload typeInvoice={typeInvoice}>
        <Dialog
          title={<Header />}
          forwardRef={dialog.refDialog}
          width={50}
          footer={
            <div
              className="d-flex justify-content-end align-items-center"
              style={{
                gap: "10px",
              }}
            >
              <Back />
              <Next />
            </div>
          }
          closeCallback={close}
        >
          <StepsHeader />
          <Step1 />
          <Step2 />
        </Dialog>
      </WrapperMassiveInvoiceDownload>
    </>
  );
}
