import React, { createContext } from "react";
import useMassiveInvoiceDownload from "./useMassiveInvoiceDownload";
import Step1 from "./Step1";
import Step2 from "./Step2";
import StepsHeader from "./StepsHeader";
import Next from "./Next";
import Back from "./Back";
import Header from "./Header";

/**
 * @type {import("react").Context<import("./useMassiveInvoiceDownload/types").ReturnMassiveInvoiceDownload|undefined>}
 */
export const ContextMassiveInvoiceDownload = createContext(undefined);

export default function MassiveInvoiceDownload() {
  const hook = useMassiveInvoiceDownload();

  return (
    <ContextMassiveInvoiceDownload.Provider value={hook}>
      <div className="p-2">
        <StepsHeader />

        {hook.state.step === 0 && <Step1 />}
        {hook.state.step === 1 && <Step2 />}

        <Back />
        <Next />
      </div>
    </ContextMassiveInvoiceDownload.Provider>
  );
}

/**
 * Render the invoice massive download elements
 * @param {import("./types").PropsMassiveInvoiceDownload} props - Props
 */
export function WrapperMassiveInvoiceDownload({ children = <></> , typeInvoice = "facturasE" }) {
  const hook = useMassiveInvoiceDownload(typeInvoice);

  return <ContextMassiveInvoiceDownload.Provider value={hook}>
    {children}
  </ContextMassiveInvoiceDownload.Provider>;
}

export { Step1, Step2, StepsHeader, Next, Back , Header };
